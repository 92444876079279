import classes from './DisplayPhoneNumber.module.scss';

import React from 'react';
import PhoneInput from './react-phone-input-2/src/index';
import cnj from '../utils/cnj';

interface DisplayPhoneNumberProps {
  value?: any;
  onChange?: (value: any) => void;
  error?: string;
  helperText?: string;
  country?: string;
  disabled?: boolean;
  bordered?: boolean;
  isFocus?: boolean;
  onBlur?: Function;
  onFocus?: Function;
  width?: number;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
}
const DisplayPhoneNumber: React.FC<DisplayPhoneNumberProps> = ({
  value,
  country,
  className,
  inputClassName,
  placeholder,
}) => {
  return (
    <PhoneInput
      specialLabel=""
      placeholder={placeholder}
      country={country}
      value={value}
      containerClass={cnj(classes.containerClass, className)}
      inputClass={cnj(classes.inputClass, inputClassName)}
      buttonClass={classes.buttonClass}
      dropdownClass={classes.dropdownClass}
      searchClass={classes.searchClass}
      inputProps={{
        type: 'text',
        placeholder,
        readOnly: true,
        spellCheck: false,
      }}
    />
  );
};

export default DisplayPhoneNumber;
